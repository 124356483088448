export function toBool(value: string | boolean) {
	return value === 'true' || value === true;
}

export function toNumber(value: string | number) {
	if (typeof value === 'number') {
		return value;
	}

	const parsedNumber = parseInt(value, 10);
	if (!isNaN(parsedNumber)) {
		return parsedNumber;
	}

	return null;
}

export function toFloat(value: string | number) {
  if (Number(value) === value && value % 1 !== 0) {
    return value;
  }

	const parsedFloat = parseFloat(value);
	if (!isNaN(parsedFloat)) {
		return parsedFloat;
	}

	return null;
}
