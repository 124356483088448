import Constants from "./constants";

const constructLayout = (data) => {
	let layout = data.layout.toLowerCase();

	if (layout === Constants.LAYOUT_FOOTWEAR) {
		return Constants.LAYOUT_FOOTWEAR;
	}

	return Constants.LAYOUT_APPAREL
}

export default class DataParser {

  constructor(jsonData) {
    this.defaultValues();
    this.parseJSON(jsonData);
  }

  defaultValues() {
    // top-level
    this.category = null;
    this.id = -1;
    this.layout = null;
    this.name = null;

    // nested in json_data
    this.styleCode = null;

    // slides array
    this.slides = [];

    // extra image assets
    this.assets = {};

    // timestamps
    this.created_at = null;
    this.updated_at = null;
    this.deleted_at = null;
  }

  storeCustomData(data) {
    // custom to this data parser (v1)
    this.category = data.category;  // leave mixed-case until after slides are parsed below
  }

  storeCommonData(data) {
    // standard props for all data parsers
    this.id = data.id;
    this.layout = constructLayout(data);
    this.name = data.name;
    this.styleCode = data.json_data.styleCode;

    // timestamps
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.deleted_at = data.deleted_at;
  }

  storeTextData(data) {
    // speedshop text friendly property names
    this.textHeadline = data.json_data.text1;
    this.textProductName = data.json_data.text2;
    this.textTech1Headline = data.json_data.text3;
    this.textTech1BodyCopy = data.json_data.text4;
    this.textTech2Headline = data.json_data.text5;
    this.textTech2BodyCopy = data.json_data.text6;
    this.textMoreColors = data.json_data.text7;
    this.textSubHeadline = data.json_data.text8;

    // high heat text friendly property names
    this.textBigHeadline = data.json_data.text1;
    this.textProductSmall = data.json_data.text2;
    this.textBodyCopy = data.json_data.text3;
    this.textQuote = data.json_data.text4;
    this.textQuoteAttribution = data.json_data.text5;
  }

  storeSpecialModeFlags(data) {
    // special modes
    // shared
    this.blank = data.json_data.blank;
    // vault
    this.snkrs = data.json_data.snkrs;
    this.jordan = data.json_data.jordan;
    this.SNKRSImages = data.json_data.SNKRSImages;
    this.disabled = data.json_data.disabled;
    this.demo = data.json_data.demo;
    this.branding = data.json_data.branding || null;

    // speedshop special modes for v2
    this.slidePreview =  window.slidePreview || data.json_data.slidePreview;
    this.slideshowOnly = window.slideshowOnly || data.json_data.slideshowOnly;
    this.videoOnly = window.videoOnly || data.json_data.videoOnly;
  }

  storeSlidesData(data) {
    if(data.json_data.slides) {
      // switch from map to reduce to skip slide data objects that aren't populated
      let category = this.category;
      let layout = this.layout;
      this.slides = data.json_data.slides.reduce(function(result, el) {
        // add new object to empty array if data looks good
        if(el.assetUrl) {
          result.push({
            primaryColor: el.primaryColor,
            subType: el.subType,
            assetId: el.assetId,
            assetUrl: el.assetUrl[category][layout],
            isIpad: category.toLowerCase() === Constants.CATEGORY_IPAD,
            duration: el.duration,
          });
        }
        // pass the array forward without adding to it
        return result;
      }, []);

      // remove 3rd slide for testing a single tech info
      // this.slides = [];
      // this.slides.splice(0,2);
    }
  }

  storeVaultAssets(data) {
    if(data.json_data.assets) {
      let assetsObj = data.json_data.assets;
      for (var attr in assetsObj) {
        if(assetsObj.hasOwnProperty(attr)) {
          this.assets[attr] = assetsObj[attr];
        }
      }
    }
  }

  transformData(data) {
    if (this.category?.toLowerCase) {
      this.category = this.category.toLowerCase();
    }
  }

  parseJSON(data) {
    this.storeCustomData(data);
    this.storeCommonData(data);
    this.storeTextData(data);
    this.storeSpecialModeFlags(data);
    this.storeSlidesData(data);
    this.storeVaultAssets(data);
    this.transformData(data);
    _store.set(Constants.DATA_PARSED, this);
  }

  containerClass() {
    // return Constants.CATEGORY_TO_CSS_CLASS['square-test'];
    // map category (animation type) to css class that defines the main container size
    return Constants.CATEGORY_TO_CSS_CLASS[this.category];
  }

  isPortrait() {
    return this.category === Constants.CATEGORY_TV || this.category === Constants.CATEGORY_HIGH_HEAT;
  }

  isSpeedshop() {
    return (this.category === Constants.CATEGORY_TV || this.category === Constants.CATEGORY_IPAD);
  }

  isHOI() {
    return (this.category === Constants.CATEGORY_PEDESTAL || this.category === Constants.CATEGORY_HIGH_HEAT);
  }

  isSlideshow() {
    return this.slideshowOnly;
  }

  isVideo() {
    return this.videoOnly;
  }

  apiVersion() {
    return 1;
  }
}
